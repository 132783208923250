<app-header></app-header>

<div class="page"></div>
<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
>
@if ( !loading ) {
<div class="container">
    <div class="alert-box">
        <label for="alert-text">Text for alert</label>
        <textarea id="alert-text" rows="1" [value]="alertMessage" readonly>
        </textarea>
    </div>

    @for (agent of agents; track $index) {
    <div class="agent-box">
        <div class="remove-btn-wrapper">
            <button class="remove-btn" (click)="removeAgent($index)">
                Remove
            </button>
        </div>
        <div class="agent-details">
            <div>
                <label for="agent-name-{{ $index }}">Name</label>
                <input
                    id="agent-name-{{ $index }}"
                    type="text"
                    [(ngModel)]="agent.name"
                    required
                    #nameInput="ngModel"
                />
                @if (nameInput.invalid && nameInput.touched) {
                <div class="error-message">Name is required.</div>
                }
            </div>
            <div>
                <label for="agent-phone-{{ $index }}">Mobile Number</label>
                <input
                    id="agent-phone-{{ $index }}"
                    type="text"
                    [(ngModel)]="agent.phone"
                    required
                    pattern="^\+?[1-9]\d{1,14}$"
                    #phoneInput="ngModel"
                />
                @if (phoneInput.invalid && phoneInput.touched) {
                <div class="error-message">Mobile Number is required.</div>
                } @if(phoneInput.errors?.['pattern']) {
                <div class="error-message">
                    Invalid phone number format. Must follow international
                    format (+123456789).
                </div>
                }
            </div>
        </div>
        <div class="agent-settings">
            <div>
                <label for="agent-delay-{{ $index }}">Minutes Delay</label>
                <input
                    id="agent-delay-{{ $index }}"
                    type="number"
                    [(ngModel)]="agent.retry_interval"
                    min="0"
                    required
                    #delayInput="ngModel"
                />
                @if (delayInput.invalid && delayInput.touched) {
                <div class="error-message">
                    Delay is required and must be 0 or more.
                </div>
                }
            </div>
            <div>
                <label for="agent-repeats-{{ $index }}">Repeats</label>
                <input
                    id="agent-repeats-{{ $index }}"
                    type="number"
                    [(ngModel)]="agent.max_retries"
                    min="0"
                    required
                    #repeatsInput="ngModel"
                />
                @if (repeatsInput.invalid && repeatsInput.touched) {
                <div class="error-message">
                    Repeats is required and must be 0 or more.
                </div>
                }
            </div>
        </div>
    </div>
    }
    <div class="button-wrapper">
        <button (click)="addAgent()">Add another agent</button>
        <button (click)="saveAgents()" [disabled]="!allAgentsValid()">
            Save
        </button>
    </div>
</div>
}
